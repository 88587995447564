// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArSearchInput-module--ar-gradient-blue--W7dcQ";
export var arGradientLight = "ArSearchInput-module--ar-gradient-light--OeqZu";
export var arH1 = "ArSearchInput-module--ar-h1--wn3QC";
export var arH1Mobile = "ArSearchInput-module--ar-h1-mobile--+kNcb";
export var arH2 = "ArSearchInput-module--ar-h2--UY5-E";
export var arH2Mobile = "ArSearchInput-module--ar-h2-mobile--nbiJZ";
export var arH3 = "ArSearchInput-module--ar-h3--IZq0Y";
export var arH3Mobile = "ArSearchInput-module--ar-h3-mobile--0thUJ";
export var arH4 = "ArSearchInput-module--ar-h4--qftM9";
export var arH4Mobile = "ArSearchInput-module--ar-h4-mobile--3jYK2";
export var arH5 = "ArSearchInput-module--ar-h5--kk+eK";
export var arH5Mobile = "ArSearchInput-module--ar-h5-mobile--TiJI9";
export var arLinkDesktop = "ArSearchInput-module--ar-link-desktop--GI08S";
export var arLinkMobile = "ArSearchInput-module--ar-link-mobile--nxtfI";
export var arParagraphBase = "ArSearchInput-module--ar-paragraph-base--oB+4j";
export var arParagraphBaseMedium = "ArSearchInput-module--ar-paragraph-base-medium--0YrwL";
export var arParagraphSmall = "ArSearchInput-module--ar-paragraph-small--DpxCo";
export var arParagraphSmallMedium = "ArSearchInput-module--ar-paragraph-small-medium--PqboU";
export var arParagraphXl = "ArSearchInput-module--ar-paragraph-xl--ktJEO";
export var arParagraphXlMedium = "ArSearchInput-module--ar-paragraph-xl-medium--gGyxY";
export var arParagraphXsmall = "ArSearchInput-module--ar-paragraph-xsmall--DH-3R";
export var arParagraphXsmallMedium = "ArSearchInput-module--ar-paragraph-xsmall-medium--boFUq";
export var arQuote = "ArSearchInput-module--ar-quote--PqbtA";
export var arQuoteMobile = "ArSearchInput-module--ar-quote-mobile--m4YwO";
export var arTransition = "ArSearchInput-module--ar-transition--sXN9H";
export var container = "ArSearchInput-module--container--gxo0u";
export var crossIcon = "ArSearchInput-module--crossIcon--KCzG0";
export var searchIcon = "ArSearchInput-module--searchIcon--WAGJf";