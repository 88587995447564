// extracted by mini-css-extract-plugin
export var arGradientBlue = "ArBlogHeader-module--ar-gradient-blue--rWRnp";
export var arGradientLight = "ArBlogHeader-module--ar-gradient-light--trNa7";
export var arH1 = "ArBlogHeader-module--ar-h1--DGFq8";
export var arH1Mobile = "ArBlogHeader-module--ar-h1-mobile--yg51z";
export var arH2 = "ArBlogHeader-module--ar-h2--8sEYp";
export var arH2Mobile = "ArBlogHeader-module--ar-h2-mobile--P3XcZ";
export var arH3 = "ArBlogHeader-module--ar-h3--CHk0A";
export var arH3Mobile = "ArBlogHeader-module--ar-h3-mobile--ijS0J";
export var arH4 = "ArBlogHeader-module--ar-h4--iCPR-";
export var arH4Mobile = "ArBlogHeader-module--ar-h4-mobile---46qu";
export var arH5 = "ArBlogHeader-module--ar-h5--cgeLl";
export var arH5Mobile = "ArBlogHeader-module--ar-h5-mobile--EADIM";
export var arLinkDesktop = "ArBlogHeader-module--ar-link-desktop--oP9x6";
export var arLinkMobile = "ArBlogHeader-module--ar-link-mobile--Txt1b";
export var arParagraphBase = "ArBlogHeader-module--ar-paragraph-base--c9hFk";
export var arParagraphBaseMedium = "ArBlogHeader-module--ar-paragraph-base-medium--yg9t5";
export var arParagraphSmall = "ArBlogHeader-module--ar-paragraph-small--GdEFy";
export var arParagraphSmallMedium = "ArBlogHeader-module--ar-paragraph-small-medium--7uoDh";
export var arParagraphXl = "ArBlogHeader-module--ar-paragraph-xl--gYj5a";
export var arParagraphXlMedium = "ArBlogHeader-module--ar-paragraph-xl-medium--D-rV2";
export var arParagraphXsmall = "ArBlogHeader-module--ar-paragraph-xsmall--5v4DW";
export var arParagraphXsmallMedium = "ArBlogHeader-module--ar-paragraph-xsmall-medium--o0rk-";
export var arQuote = "ArBlogHeader-module--ar-quote--EE3Ct";
export var arQuoteMobile = "ArBlogHeader-module--ar-quote-mobile--oZslq";
export var arTransition = "ArBlogHeader-module--ar-transition--+Be-2";
export var authors = "ArBlogHeader-module--authors--7L3vE";
export var categories = "ArBlogHeader-module--categories--wK-kp";
export var container = "ArBlogHeader-module--container--WuJmO";